<script setup lang="ts">
const { notifications, removeOne } = useNotifications();
</script>
<template>
  <div
    data-testid="notification-container"
    class=" notification-container">
    <div class="container position-relative">
      <LayoutNotification
        v-for="notification in notifications"
        :key="notification.id"
        :notification="notification"
        @click:close="removeOne(notification.id)"
      />
    </div>
  </div>
</template>
